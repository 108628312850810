import { getBoolean } from "services/util/auxiliaryUtils";
import { ENVIRONMENTS_FE } from "constants/settings";

/**
 * getDomain
 * 
 * @param {*} env (DEV, TST, PRD)
 */
export const getDomain = (env) => {
    const locationHost = document.location.host;

    //1. only env DEV (FE & BE)
    if (env === ENVIRONMENTS_FE.DEV) return process.env.REACT_APP_API_URL_DEV

    //3. in FE env development 
    if (process.env.NODE_ENV === 'development') {
        return (env === ENVIRONMENTS_FE.TST) ?
            process.env.REACT_APP_API_URL_TST :
            process.env.REACT_APP_API_URL_PRD
    }

    // 4. testing (REACT_APP_IS_DEBUGGER)
    if (getBoolean(process.env.REACT_APP_IS_DEBUGGER)) {
        return (env === ENVIRONMENTS_FE.TST) ?
            process.env.REACT_APP_API_URL_TST :
            process.env.REACT_APP_API_URL_PRD;
    }
    //All on servers
    return locationHost;
};

export function isDevelopment() {
    return (process.env.NODE_ENV === 'development')
};

/**
 * isEnvDevelopment
 * @returns 
 */
export function isEnvDevelopment() {
    return ([ENVIRONMENTS_FE.DEV, ENVIRONMENTS_FE.TST].includes(process.env.REACT_APP_ENV))
}