import format from 'date-fns/format';
import { isUserFieldV2 } from 'services/user';
import { intlMessages } from 'services/util/auxiliaryUtils';

export const getLastStateActive = (timeline) => {
    return timeline.find(element => !!element.active)
}

export const formatTimelineDate = (date, locale) => format(
    new Date(date),
    'PPPpp',
    {
        locale:locale
    }
)

export const formatTimelineDateV2 = (date, userTypeID) => {
    const dateArranged = isUserFieldV2(userTypeID) ? date.split(" ")[0].split("-").reverse().join("/") : date.split("T")[0].split("-").reverse().join("/") 
    const hours = isUserFieldV2(userTypeID) ? date.split(" ")[1].split(":") : date.split("T")[1].split(":")

    return dateArranged + ' | ' + hours[0] + ':' + hours[1]
}

export const buildTimelineData = (timeline, arrangeComment, locale) => {
    let timelineData = [];

    timeline.forEach(element => {
        timelineData.push({
            date: format(
                new Date(element.updated_at),
                'PPPpp',
                {
                    locale:locale
                }),
            title: intlMessages(`state.name.${element.description}`),
            description: arrangeComment(element.state_comments),
            active: element.active,
            tir_new: element?.tir_new,
            finantial_analysis: element?.finantial_analysis,
            max_additional_cost: element?.max_additional_cost
        });
    });

    return timelineData;
}