import { lazy } from 'react';
import { connect } from "react-redux";
import lazyLoader from 'services/lazyLoader';
import { withTracker } from 'services/withTracker';

//@ts-ignore
const CreateAssetManagementContainer = lazy(() => lazyLoader(() => import("pages/Restricted/CreateAssetManagement")));

/**
 * @context Route to CreateAssetManagement
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const CreateAssetManagement = () => (<CreateAssetManagementContainer/>);

const mapStateToProps = () => ({
});

export default connect(mapStateToProps,
    {  }
)(withTracker(CreateAssetManagement, 'Create Asset Management'));
