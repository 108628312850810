import * as Sentry from '@sentry/browser';
import { ENVIRONMENTS_FE } from "constants/settings";
import packageJson from '../../../package.json';

/**
 * SentryInit
 * @constructor
 */
export const SentryInit = () => {
    if (process.env.NODE_ENV === 'production') {
        Sentry.init(
            {
                dsn: process.env.REACT_APP_SENTRY_DNS,
                environment: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SENTRY_ENVIROMENT : 'development',
                release: packageJson.name + '@' + packageJson.version,
                integrations: [
                    new Sentry.Integrations.Breadcrumbs({
                        console: process.env.REACT_APP_SENTRY_ENVIROMENT === ENVIRONMENTS_FE.DEV
                    })],
                normalizeDepth: 5, //6 is too much information to send
                beforeSend(event, hint) {
                    event.tags = {
                        ...event.tags,
                        is_false_positive: isFalsePositive(event, hint)
                    };
                    // Add error message to extras
                    event.extra = {
                        ...event.extra,
                        evMsg: event?.message,
                        evExVal: event?.exception?.values?.[0]?.value,
                        hintOriMsg: hint?.originalException?.message,
                        hintSyntMsg: hint?.syntheticException?.message
                    };
                    return event;
                }
            }
        );
    }
};

const FALSE_POSITIVE_REGEX_ENTRIES = [
    /Could not find a 3d context/,
    /InvalidValueError/,
    /undefined is not an object \(evaluating 'a\.L'\)/,
    /Objects are not valid as a React child/,
    /this\.Kg is not a function/,
    /a\.onContextLost is not a function/,
    /Failed to execute 'insertBefore' on 'Node'/,
    /Illegal invocation TypeError \/js\/widget\.js Object\.postMessage/,
    // eslint-disable-next-line no-useless-escape
    /Error sending request: Failed to fetch RestError .+\Ta Ta\(main\)/,
    /Failed to fetch TypeError \.\.\/node_modules\/@sentry\/src\/nstrument\.ts \?\(chrome-extension/,
    /Right-hand side of 'instanceof' is not an object/,
    /Cannot read properties of null \(reading 'getAt'\)/,
    /Cannot read properties of null \(reading 'zoom'\)/,
    /JSON\.parse: unexpected character/,
    /Loading CSS chunk/,
    /Error sending request: Failed to fetch RestError .+fetchHttpClient/,
    /No error message RestError .+deserializationPolicy/,
    /Object doesn't support property or method 'forEach'/,
    /a\.stopPropagation is not a function/,
];


const matchesAnyRegex = (string, regexArray) => regexArray.some((r) => !!string.match(r));

const isFalsePositive = (event, hint) => {
    return (
        (!!event?.message && matchesAnyRegex(event?.message, FALSE_POSITIVE_REGEX_ENTRIES)) ||
        (!!event?.exception?.values?.[0]?.value &&
            matchesAnyRegex(event?.exception?.values?.[0]?.value, FALSE_POSITIVE_REGEX_ENTRIES)) ||
        // @ts-expect-error message
        (!!hint?.originalException?.message &&
            // @ts-expect-error message
            matchesAnyRegex(hint?.originalException?.message, FALSE_POSITIVE_REGEX_ENTRIES)) ||
        (!!hint?.syntheticException?.message &&
            matchesAnyRegex(hint?.syntheticException?.message, FALSE_POSITIVE_REGEX_ENTRIES))
    );
};


const SENTRY_LEVEL = {
    0: 'Critical',
    1: 'Fatal',
    2: 'Error',
    3: 'Warning',
    4: 'Info',
    5: 'Debug',
    6: 'Log',
}

/**
 * SentryCaptureException
 * 
 * @param {
 *  level : 3,//Error 
 *  message : 'Something went wrong',
 *  context : { name: 'CacheBusterData',
                data:{  latestVersion: '1.2',
                        currentVersion: '1.0',
                        location: 'facility'
                        }
            }
 *  extrasContext : {facilityID: 123456},
 *  tags : {api: 'failities', component: 'header' },
 *  user : {userType:5, username: 'manager' },
 * } props 
 * 
 */
export function SentryCaptureException(props) {

    const scope = new Sentry.Scope();

    //#region props 
    let level = props?.level ?? 3;
    let message = props?.message ?? 'ALERT Something went wrong';
    let context = props?.context ?? null;
    let extrasContext = props?.extrasContext ?? null;
    let tags = props?.tags ?? null;
    let user = props?.user ?? null;
    //#endregion


    // level
    scope.setLevel(Sentry.Severity[SENTRY_LEVEL[level]]);

    // context
    if (!!context) scope.setContext(context.name, context.data);

    // extrasContext
    if (!!extrasContext) scope.setExtras(extrasContext);

    // tags
    if (!!tags) scope.setTags(tags);

    // user
    if (!!user) scope.setUser(user);

    Sentry.captureMessage(message, scope);

};


