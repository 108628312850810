import pt_PT from "lngProvider/locales/pt_PT";
import es_ES from "lngProvider/locales/es_ES";
import en_GB from "lngProvider/locales/en_GB";
import pl_PL from "lngProvider/locales/pl_PL";
import it_IT from "lngProvider/locales/it_IT";
import clone from 'fast-copy';
import format from "date-fns/format";
import { COMPANY_IDS } from "constants/settings";

export const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const TELEPHONE_REGEXP = /^\d{9}$/;
export const FLOAT_REGEXP = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;
export const NUMBER_REGEXP = /^[0-9]*$/;
export const isValidEmail = (email) => EMAIL_REGEXP.test(email);
export const PASSWORD_CHECK = {
    PASSWORD_HAS_LOWERCASE_REGEXP: /(?=.*[a-z])/, // Matches if password has at least 1 lower case character and 8 minimum characters
    PASSWORD_HAS_UPPERCASE_REGEXP: /(?=.*[A-Z])/, // Matches if password has at least 1 upper case character and 8 minimum characters
    PASSWORD_HAS_NUMBER_REGEXP: /(?=.*[0-9])/, // Matches if password has at least 1 number
    PASSWORD_HAS_DIGIT_REGEXP: /^(?=.*\d)[a-zA-Z\d.,@$!%*?&]{8,}$/, // Matches if password has at least 1 digit and 8 minimum characters
    PASSWORD_HAS_SPECIAL_CHARACTER: /(?=(.*[-+_!@#$%^&*.,?]){1,})/, // Matches if password has at least 2 special characters
    PASSWORD_IS_VALID: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&]{8,}$/ // All the above checks in one
}
const getLocaleCodeByLS = () => (!!localStorage.getItem('LOCALE_CODE') ? localStorage.getItem('LOCALE_CODE') : 'en');//default EN
export const getBoolean = (value) => (typeof value === "boolean" ? value : (value === 'true' || value === 'True' || value === 'Sim' || value === 'Yes'));
export const getNA = (value) => value === 'NA' || value === 'N.A.' || !isDefined(value);
export const isDefined = (input) => (input !== undefined && input !== null);
export const isNumber = (value) => typeof value === "number";

export const isFieldDefined = (field) => (isDefined(field) && field !== '');
export const isNumberDefined = (value) => (isFieldDefined(value) && !isNaN(parseFloat(value)));

/**
 * removeEmptyInObj
 * 
 * @param {} obj 
 */
export const removeEmptyInObj = function (obj) {
    for (let key in obj) if ((obj?.[key] !== 0 && !obj?.[key]) || obj[key] === "") delete obj[key];
    return clone(obj);
};

export const generateRandomString = () => (Math.random().toString(36).substring(2, 4) + Math.random().toString(36).substring(2, 4).toUpperCase());

export const isExistWord = (string, word) => (string.search(word) !== -1);

/**
 * trimAll removes all whitespace
 * 
 * @param {*} str 
 */
export const trimAll = (str) => (str.replace(/\s/g, ''));

/**
 * isEmpty array
 * @param array
 * @returns {boolean}
 */
export const isEmptyArray = (array) => (array.length === 0);


/**
 * intlMessages
 * 
 * @param id - string (keyword)
 * @param values - {*}
 * @returns {*}
 */
export const intlMessages = (id, values = null) => {
    let translation = getTranslations()[id];

    //replacements
    if (!!values) {
        for (var prop in values) {
            if (Object.prototype.hasOwnProperty.call(values, prop))
                translation = translation.replace(`{${prop}}`, values[prop]);
        }
    }
    return !!translation ? translation : `PLEASE TRANSLATE THIS (${id}) KEYWORD`;
};
/**
 * getTranslations
 *
 * @param language
 * @returns {*}
 */
const getTranslations = () => {
    let language = getLocaleCodeByLS();
    switch (language ?? 'en') {
        case 'pt':
            return pt_PT;
        case 'es':
            return es_ES;
        case 'en':
            return en_GB;
        case 'it':
            return it_IT;
        case 'pl':
            return pl_PL;
        default:
            return en_GB;
    }
};


export const getLetterAvatar = (type, lettersNumber) => (type.substr((-type.length), lettersNumber).toUpperCase());

export const objectToFormData = (object) => {
    var bodyFormData = new FormData();
    for (var param in object) {
        if (param === "attachment") {
            isDefined(object[param]) && object[param].forEach((file) => {
                bodyFormData.append("attachment", file)
            })
        }
        else {
            bodyFormData.append(param, object[param])
        }
    }
    return bodyFormData;
}

// we have to do some additional check
export const isEmptyObject = (obj) => (Object.keys(obj).length === 0 && obj.constructor === Object);

export const dateFormatted = (date, withTime = false) => {
    let date_formatted = date;

    if (!!date_formatted) {
        date_formatted = new Date(date);
        //date_formatted = date_formatted.getTime();
    }
    return date_formatted;
}

/**
 * method to remove time and return the locale hour 
 * @param {string} date 
 * @returns string
 */
export const removeTimeFromLocale = (date) => {
    let dateSelected = date;
    if (!!dateSelected) {
        return format(date,"yyyy-MM-dd");
    }
}


/**
 * parseNumberWithToFixed
 * 
 * @param {*} value 
 * @param {*} decimalPlaces 
 */
export const parseNumberWithToFixed = (value, decimalPlaces = 2) => (
    isDefined(decimalPlaces) ? parseFloat(parseFloat(value).toFixed(decimalPlaces)) : value
)


/**
 * calcConvertionToUnit
 * 
 * @param {*} value 
 * @param {*} rule ex. currencykWhToMWh
 * @param {*} decimalPlaces 
 */
export const calcConvertionToUnit = (value, rule, decimalPlaces = 2) => {

    switch (rule) {
        //From currency KiloWatt hours to MegaWatt hours e
        case "currencykWhToMWh":
            value = value / 1000;
            break;
        //From currency MegaWatt hours to kiloWatt hours
        case "currencyMWhTokWh":
            value = value * 1000;
            break;
        //Default
        default:
            break;
    }

    return parseNumberWithToFixed(value, decimalPlaces);
}

export function subscribedPowerUnit(companyID) {
    let unit = 'kva';

    switch (companyID) {
        case COMPANY_IDS.EDP_IT:
            unit = 'kwh';
            break;
        default:
            break;
    }
    
    return unit;

}


export const scrollToTopContainer = (id) => {
    let top = document.getElementById(id);

    top.scrollTop = 0;
}

export function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

/**
 * Method to sync scrolls between two DOM elements. MUST have 2 refs elements (useRef)
 * @param {ref} element1 
 * @param {ref} element2 
 */
export const scrollSync = (element1, element2) => {
    element1.current.scrollLeft = element2.current.scrollLeft
}

export const getGeneralParseNumber = (value, delimiter = ',') => {
    if (isNumber(value)) {
        return value;
    } else if (delimiter === ',' && !!value) {
        value = value.split(delimiter).join('.');
    }
    return parseFloat(value);
};

export const checkEquality = (value1, value2) => {
    if (Array.isArray(value1) && Array.isArray(value2)) {
        if (value1.length !== value2.length) return false;
        for (let i = 0; i < value1.length; i++) {
            if(value1[i] !== value2[i]) return false;
        }
        return true
    } else {
        return value1 === value2;
    }
} 

export const handleCloseDialog = (_, reason, closeHandler) => {
    if (['backdropClick', 'escapeKeyDown'].includes(reason)) return false;
    if (typeof onCloseHandler === "function") closeHandler();
};

export const arrayBufferToJSON = ( arrayBuffer ) => {
    return JSON.parse(String.fromCharCode.apply(null, Array.from(new Uint8Array(arrayBuffer?.response?.data))))
}