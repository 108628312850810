import { lazy } from 'react';
import { connect } from "react-redux";
import lazyLoader from 'services/lazyLoader';
import { isUserFieldV2 } from 'services/user';
import { withTracker } from 'services/withTracker';

const DashboardContainer = lazy(() => lazyLoader(() => import("pages/Restricted/Dashboard")));
const DashboardContainerV2 = lazy(() => lazyLoader(() => import("pages/Restricted/Dashboard/indexV2")));


/**
 * @context Route to Dashboard
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Dashboard = (props) => ( isUserFieldV2(props.userTypeID) ? <DashboardContainerV2/> : <DashboardContainer/> );


// Dashboard.propTypes = {
// };


const mapStateToProps = () => ({
});

export default connect(mapStateToProps,
    {  }
)(withTracker(Dashboard, 'Dashboard'));
