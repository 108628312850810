import { lazy } from 'react';
import { connect } from "react-redux";
import lazyLoader from 'services/lazyLoader';
import { withTracker } from 'services/withTracker';

//@ts-ignore
const CreateProcessContainer = lazy(() => lazyLoader(() => import("pages/Restricted/CreateProcess")));

/**
 * @context Route to ProcessDetails
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const CreateProcess = () => (<CreateProcessContainer/>);

// ProcessDetails.propTypes = {
// };


const mapStateToProps = () => ({
});

export default connect(mapStateToProps,
    {  }
)(withTracker(CreateProcess, 'Create Process'));
